import { IBank, IDateToday, IFunctionHelper } from "./types";
import countries from '../_data/countries.json'
import categories from '../_data/categories.json'
import industries from '../_data/industries.json'
import registrations from '../_data/registrations.json'
import banks from '../_data/banks.json'
import currencies from '../_data/currencies.json'
import staffs from '../_data/staffs.json'
import networks from '../_data/networks.json'
import complianceStatus from '../_data/statuses.json'
import ENV from "./env.util";
import { CurrencyType } from "./enums.util";
/**
 * 
 * @param data 
 * @param from 
 * @param to 
 * @returns 
 */
const reposition = (data: Array<any>, from: number, to: number): Array<any> => {

    let temp: Array<any> = []
    let result: Array<any> = [];

    temp = [...data];

    // remove item from the {from} index and save
    const item = data.splice(from, 1)[0];

    if (item) {

        result = [...data]; // spread out the remaining items
        result.splice(to, 0, item) // add the item back

    } else {
        result = [...temp];
    }

    return result;

}

/**
 * 
 * @param data 
 * @returns 
 */
const splitByComma = (data: string): Array<string> => {

    let result: Array<string> = [];
    let temp: Array<string> = [];

    const split = data.split(',')

    // process the string
    if (split.length > 0) {

        split.forEach((val) => {

            // if(val.includes(' ')){

            //     const npl = val.split(' ');
            //     temp = temp.concat(npl)

            // }else{
            //     temp.push(val)
            // }

            temp.push(val.trim())

        })

    }

    // clean the result
    for (let i = 0; i < temp.length; i++) {

        if (temp[i]) {
            result.push(temp[i])
        }

    }

    return result;

}

/**
 * @name dateToday
 * @param d 
 * @returns 
 */
const dateToday = (d: string | Date): IDateToday => {

    const today = d !== '' ? new Date(d) : new Date(Date.now());

    const year = today.getFullYear().toString();
    const month = (today.getMonth() + 1) < 10 ? `0${(today.getMonth() + 1)}` : `${(today.getMonth() + 1)}`;
    const date = today.getDate() < 10 ? `0${today.getDate()}` : `${today.getDate()}`;
    const hour = today.getHours() < 10 ? `0${today.getHours()}` : `${today.getHours()}`;
    const minutes = today.getMinutes() < 10 ? `0${today.getMinutes()}` : `${today.getMinutes()}`;
    const seconds = today.getSeconds() < 10 ? `0${today.getSeconds()}` : `${today.getSeconds()}`;
    const ISO = today.toISOString()
    const datetime = today.getTime()

    return { year, month, date, hour, minutes, seconds, ISO, dateTime: datetime }

}

/**
 * @name monthsOfYear
 * @param val 
 * @returns 
 */
const monthsOfYear = (val: string | number): string => {

    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
    const index = parseInt(val.toString(), 10);
    return months[index - 1];

}


/**
 * @name roundFloat
 * @param val 
 * @returns 
 */
const roundFloat = (val: number): number => {
    return Math.round(val * 100 + Number.EPSILON) / 100;
}

/**
 * @name addElipsis
 * @param val 
 * @param size 
 * @returns 
 */
const addElipsis = (val: string, size: number): string => {

    let result = val.substring(0, size) + '...';
    return result;

}

/**
 * @name leadingZero
 * @param val 
 * @returns 
 */
const leadingZero = (val: number): string => {
    let result: string = '';

    if (val < 10 && val > 0) {
        result = `0${val}`
    } else {
        result = val.toString()
    }

    return result;
}

/**
 * @name formatPhone
 * @param val 
 * @param code 
 * @returns 
 */
const formatPhone = (val: string, code: string): string => {

    let result = val;

    if (code && val) {

        if (code === 'NG') {
            result = `0${val.substring(3)}`;
        } else {
            result = val;
        }

    }

    return result;

}

const getCardBin = (num: string): string => {
    let result: string = '';
    if (num) {
        result = num.slice(0, 6);
    }
    return result;
}

const getCardLast = (num: string): string => {
    let result: string = '';
    if (num) {
        result = num.slice(-4);
    }
    return result;
}

const encodeCardNumber = (num: string): string => {

    let result: string = '';
    if (num) {
        result = `${getCardBin(num)}******${getCardLast(num)}`
    }
    return result;

}

const readCountries = (): Array<any> => {

    let result: Array<any> = countries;
    result = sortData(result, 'name');
    return result

}

const sortData = (data: Array<any>, filter: string = ''): Array<any> => {

    let sorted: Array<any> = [];

    if (filter !== '') {

        sorted = data.sort((a, b) => {
            if (a[filter].toString() < b[filter].toString()) { return -1 }
            else if (a[filter].toString() > b[filter].toString()) { return 1 }
            else { return 0 }
        })

    }

    if (filter === '') {

        sorted = data.sort((a, b) => {
            if (a.toString() < b.toString()) { return -1 }
            else if (a.toString() > b.toString()) { return 1 }
            else { return 0 }
        })

    }

    return sorted;
}

const attachPhoneCode = (code: string, phone: string, include: boolean): string => {

    let result: string = '';

    // format phone number
    let phoneStr: string;
    if (code.includes('-')) {
        phoneStr = code.substring(3);
    } else if (code.includes('+')) {
        phoneStr = include ? code : code.substring(1);
    } else {
        phoneStr = code
    }

    result = phoneStr + phone.substring(1);

    return result;

}

const getCategories = (): Array<any> => {

    let result: Array<any> = categories;
    result = sortData(result, 'name');
    return result

}

const getIndustries = (): Array<any> => {

    let result: Array<any> = industries;
    result = sortData(result, 'name');
    return result

}

const getRegistrations = (): Array<any> => {

    let result: Array<any> = registrations;
    return result

}

const getBanks = (): Array<IBank> => {

    let result: Array<any> = banks;
    result = sortData(result, 'name');
    return result

}


const getStaffStrength = (): Array<any> => {

    let result: Array<any> = staffs;
    result = sortData(result, 'name');
    return result

}

const getStatus = (): Array<any> => {

    let result: Array<any> = complianceStatus;
    result = sortData(result, 'name');
    return result

}

const getNetworks = (): Array<any> => {

    let result: Array<any> = networks;
    result = sortData(result, 'name');
    return result

}

const getCables = (): Array<any> => {

    let result: Array<any> = [];
    result = sortData(result, 'name');
    return result

}

const getCablePlans = (): Array<any> => {

    let result: Array<any> = [];
    result = sortData(result, 'name');
    return result

}

const getBills = (): Array<any> => {

    let result: Array<any> = [];
    result = sortData(result, 'name');
    return result

}

const getBillPlans = (): Array<any> => {

    let result: Array<any> = [];
    result = sortData(result, 'name');
    return result

}

const getDataPlans = (): Array<any> => {

    let result: Array<any> = [];
    result = sortData(result, 'name');
    return result

}

const getCurrencies = (): Array<any> => {

    let result: Array<any> = currencies;
    result = sortData(result, 'name');
    return result

}

const capitalizeWord = (value: string): string => {

    const split = value.toLowerCase().split(" ");

    for (var i = 0; i < split.length; i++) {
        split[i] = split[i].charAt(0).toUpperCase() + split[i].slice(1);
    }

    return split.join(' ');

}

const shrinkWordInString = (value: string, ret: number): string => {

    const split = value.split(' ');
    let result: string = '';

    for (let i = 0; i < ret; i++) {
        result = result + ' ' + split[i];
    }

    return result;

}

const truncateText = (text: string, max: number): string => {
    return (text?.length > max) ? text.slice(0, max) + '...' : text;
}

const getChargebacks = (): Array<any> => {

    let result: Array<any> = [];

    return result

}

const objectToArray = (data: Object | any): Array<any> => {

    let result: Array<any> = [];

    for (const [key, value] of Object.entries(data)) {

        if (value && typeof (value) !== 'object') {

            let newData = {
                key: key.toString(),
                value: value.toString()
            }
            result.push(newData)

        } else if (value && typeof (value) === 'object') {

            for (const [_key, _value] of Object.entries(data)) {

                if (_value && typeof (_value) !== 'object') {

                    let _newData = {
                        key: _key.toString(),
                        value: _value.toString()
                    }

                    result.push(_newData)

                }

            }

        }

    }

    return result;

}

const getChargebackLevels = (): Array<any> => {

    let result: Array<any> = [
        {
            name: 'Level One',
            value: 'level1'
        },
        {
            name: 'Level Two',
            value: 'level2'
        },
        {
            name: 'Pre Arbitration',
            value: 'pre-arbitration'
        },
        {
            name: 'Arbitration',
            value: 'arbitration'
        }
    ];
    return result

}

const displayBalance = (value: number): string => {

    let cast: number = 0;
    let result: string = value.toLocaleString();

    if (value <= 100000) {
        result = value.toLocaleString();
    } else if (value > 100000) {

        if (value >= 1e3 && value < 1e6) {
            cast = (value / 1e3);
            result = `${cast.toFixed(2)}K`;
        } else if (value >= 1e6 && value < 1e9) {
            cast = (value / 1e6);
            result = `${cast.toFixed(2)}M`;
        } else if (value >= 1e9 && value < 1e12) {
            cast = (value / 1e9);
            result = `${cast.toFixed(2)}B`;
        } else if (value >= 1e12) {
            cast = (value / 1e12);
            result = `${cast.toFixed(2)}T`;
        }

    }

    return result

}

const extractBankCode = (code: string): string => {

    let result: string = code;

    const bankList = helperService.getBanks();
    const bank = bankList.find((x) => x.code === code);

    if (bank) {

        const providers = bank.providers;

        if (ENV.isProduction()) {

            let prodBank = providers.find((pd) => {
                if (pd.active && pd.production.name && pd.production.code) {
                    return pd
                }
            })

            if (prodBank) {
                result = prodBank.production.code;
            } else {
                result = bank.code;
            }

        } else {
            result = bank.code;
        }

    }

    return result;

}

const parseInputNumber = (value: string, type: 'number' | 'decimal'): number => {

    let result: number = 0;

    if (type === 'number') {
        result = Number.isNaN(parseInt(value)) ? 0 : parseInt(value);
    }

    if (type === 'decimal') {
        result = Number.isNaN(parseFloat(value)) ? 0 : parseFloat(value);
    }

    return result;

}

export const toDecimal = (v: number, p: number): number => {

    let result: number = v;
    result = parseFloat(v.toFixed(p));

    return result;

}

export const formatCurrency = (currency: string): string => {

    let result: string = '';

    if(currency){

        if (currency.toUpperCase() === CurrencyType.NGN) {
            result = `₦`
        } else if(currency.toUpperCase() === CurrencyType.USD){
            result = `$`
        }

    }

    return result;

}

const helperService: IFunctionHelper = {
    reposition: reposition,
    splitByComma: splitByComma,
    dateToday: dateToday,
    roundFloat: roundFloat,
    addElipsis: addElipsis,
    formatPhone: formatPhone,
    leadingZero: leadingZero,
    encodeCardNumber: encodeCardNumber,
    monthsOfYear: monthsOfYear,
    readCountries: readCountries,
    sortData: sortData,
    attachPhoneCode: attachPhoneCode,
    getCategories: getCategories,
    getIndustries: getIndustries,
    getRegistrations: getRegistrations,
    getBanks: getBanks,
    getStaffStrength: getStaffStrength,
    getStatus: getStatus,
    getNetworks: getNetworks,
    getCables: getCables,
    getCablePlans: getCablePlans,
    getBills: getBills,
    getBillPlans: getBillPlans,
    getDataPlans: getDataPlans,
    getCurrencies: getCurrencies,
    capitalizeWord: capitalizeWord,
    shrinkWordInString: shrinkWordInString,
    truncateText: truncateText,
    getChargebacks: getChargebacks,
    objectToArray: objectToArray,
    getChargebackLevels: getChargebackLevels,
    displayBalance: displayBalance,
    extractBankCode: extractBankCode,
    parseInputNumber: parseInputNumber,
    toDecimal: toDecimal,
    formatCurrency: formatCurrency,
}

export default helperService;