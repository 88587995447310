import React, { useState, useEffect, useContext } from 'react'
import { IDLayoutProps } from '../../utils/types'

import SideBar from '../partials/Sidebar'
import TopBar from '../partials/Topbar'

import UserContext from '../../context/user/userContext'
import VaceContext from '../../context/vace/vaceContext'

import { IVaceContext, IUserContext } from '../../utils/types'
import { NotPermittedType } from '../../utils/enums.util'
import EmptyBox from '../partials/Empty'
import TerraIcon from '../partials/icons/TerraIcon'

const DashboardLayout = ({ Component, pageTitle, showBack, collapsed }: Partial<IDLayoutProps>) => {

    const userContext = useContext<IUserContext>(UserContext)
    const vaceContext = useContext<IVaceContext>(VaceContext)

    const [barCollapsed, setBarCollapsed] = useState<boolean | any>(false);

    useEffect(() => {
        setBarCollapsed(collapsed);
    }, [])

    const expandCollapse = (e: any): void => {

        if (e) { e.preventDefault() }

        const sidebar = document.getElementById('ui-sidebar');
        const dashBody = document.getElementById('ui-dashboard-body');
        const sidebarPrimary = document.getElementById('ui-sidebar-primary');
        const headPrimary = document.getElementById('ui-sidebar-primary-header');
        const topbar = document.getElementById('ui-dashboard-topbar');

        if (sidebar && dashBody && sidebarPrimary && topbar) {

            //sidebar
            if (sidebar.classList.contains('sdbr--cllps')) {
                sidebar?.classList.remove('sdbr--cllps');
                sidebar?.classList.add('sdbr--open');
                setBarCollapsed(false);
            } else {
                sidebar?.classList.remove('sdbr--open');
                sidebar?.classList.add('sdbr--cllps');
                setBarCollapsed(true);
            }

            //dashboard body
            if (dashBody.classList.contains('sdbr--cllps')) {
                dashBody?.classList.remove('sdbr--cllps');
                dashBody?.classList.add('sdbr--open');
                setBarCollapsed(false);
            } else {
                dashBody?.classList.remove('sdbr--open');
                dashBody?.classList.add('sdbr--cllps');
                setBarCollapsed(true);
            }

            //sidebar primary
            if (sidebarPrimary.classList.contains('sdbr--cllps')) {
                sidebarPrimary?.classList.remove('sdbr--cllps');
                sidebarPrimary?.classList.add('sdbr--open');
                headPrimary?.classList.remove('sdbr--cllps');
                headPrimary?.classList.add('sdbr--open');
                setBarCollapsed(false);
            } else {
                sidebarPrimary?.classList.remove('sdbr--open');
                sidebarPrimary?.classList.add('sdbr--cllps');
                headPrimary?.classList.remove('sdbr--open');
                headPrimary?.classList.add('sdbr--cllps');
                setBarCollapsed(true);
            }

            //topbar
            if (topbar.classList.contains('sdbr--cllps')) {
                topbar?.classList.remove('sdbr--cllps');
                topbar?.classList.add('sdbr--open');
                setBarCollapsed(false);
            } else {
                topbar?.classList.remove('sdbr--open');
                topbar?.classList.add('sdbr--cllps');
                setBarCollapsed(true);
            }

        }

    }

    const isPermitted = (): boolean => {

        let result: boolean = true;

        if (vaceContext.response.permit && vaceContext.response.permit.code === NotPermittedType.STATUS_CODE &&
            vaceContext.response.permit.message === NotPermittedType.MESSAGE) {
            result = false
        } else if (userContext.response.permit && userContext.response.permit.code === NotPermittedType.STATUS_CODE &&
            userContext.response.permit.message === NotPermittedType.MESSAGE) {
            result = false
        } else {
            result = true
        }

        return result;

    }

    return (
        <>
            <SideBar expandSidebar={expandCollapse} barCollapsed={barCollapsed} collapsed={collapsed} />

            <main id="ui-dashboard-body" className={`ui-dashboard-body ${collapsed && collapsed === true ? 'sdbr--cllps' : 'sdbr--open'} d-body`}>

                <TopBar barType="admin" expandFunc={expandCollapse} barCollapsed={barCollapsed} collapsed={collapsed} showBack={showBack} pageTitle={pageTitle} isFixed={true} />

                <div className="ui-body-content">
                    <div className="ui-body-content-inner">

                        {
                            isPermitted() === true &&
                            <>
                                <Component collapsed={barCollapsed} />
                            </>
                        }

                        {
                            isPermitted() === false &&
                            <>
                                <div className="mrgt">
                                    <EmptyBox bgColor='#f0e8ff' size='md' >

                                        <TerraIcon 
                                            name='account'
                                            clickable={false}
                                            paths={['path1','path2']}
                                            size={38}
                                            className=''
                                            isActive={false}
                                        />

                                        <div className='font-aeonik mrgb1 fs-14 ui-line-height mrgy1 pdr2 pdl2 max-w-350 mx-auto brand-purple'>You do not have access to this page. Contact support or admin</div>

                                    </EmptyBox>
                                </div>
                            </>
                        }

                    </div>
                </div>


            </main>
        </>
    )

}

export default DashboardLayout