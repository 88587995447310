import {
    GET_LOGGEDIN_USER,
    GET_USER,
    SET_LOADING,
    SET_IS_ADMIN,
    SET_IS_SUPER,
    SET_USERTYPE,
    UNSET_LOADING,
    SET_SIDEBAR,
    SET_USER,
    GET_USERS,
    GET_KYC_LIST,
    GET_KYB_LIST,
    GET_KYC,
    GET_KYB,
    GET_BUSINESS,
    SET_COUNT,
    SET_TOTAL,
    SET_PAGINATION,
    SET_SEARCH,
    SET_RESPONSE,
    GET_OVERVIEW,
    GET_KYB_DATA,
    GET_KYC_DATA,
    GET_VERIFICATION_DATA,
    GET_SYSTEM_CONFIG,
    GET_PERMISSIONS,
    GET_PERMISSION_LIST,
    GET_DEFAULT_PERMISSIONS,
    GET_PERMISSION,
    SET_MENU_LIST,
    GET_BUSINESSES,
    GET_ADMINS,
    GET_WRITERS,
    GET_AUDITS
} from '../types';


const reducer = (state: any, action: any) => {

    switch (action.type) {
        case GET_SYSTEM_CONFIG:
            return {
                ...state,
                config: action.payload,
                loading: false
            }
        case GET_AUDITS:
            return {
                ...state,
                audits: action.payload,
                loading: false
            }
        case GET_OVERVIEW:
            return {
                ...state,
                overview: action.payload,
                loading: false
            }
        case GET_USERS:
            return {
                ...state,
                users: action.payload,
                loading: false
            }
        case GET_BUSINESSES:
            return {
                ...state,
                businesses: action.payload,
                loading: false
            }
        case GET_ADMINS:
            return {
                ...state,
                admins: action.payload,
                loading: false
            }
        case GET_WRITERS:
            return {
                ...state,
                writers: action.payload,
                loading: false
            }
        case GET_LOGGEDIN_USER:
            return {
                ...state,
                user: action.payload,
                loading: false
            }
        case GET_USER:
            return {
                ...state,
                userDetails: action.payload,
                loading: false
            }
        case GET_KYC_LIST:
            return {
                ...state,
                KYCList: action.payload,
                loading: false
            }
        case GET_KYC_DATA:
            return {
                ...state,
                KYCData: action.payload,
                loading: false
            }
        case GET_KYB_LIST:
            return {
                ...state,
                KYBList: action.payload,
                loading: false
            }
        case GET_KYB_DATA:
            return {
                ...state,
                KYBData: action.payload,
                loading: false
            }
        case GET_VERIFICATION_DATA:
            return {
                ...state,
                verification: action.payload,
                loading: false
            }
        case GET_PERMISSIONS:
            return {
                ...state,
                permissions: action.payload,
                loading: false
            }
        case GET_PERMISSION_LIST:
            return {
                ...state,
                permissionList: action.payload,
                loading: false
            }
        case GET_DEFAULT_PERMISSIONS:
            return {
                ...state,
                defaultPermissions: action.payload,
                loading: false
            }
        case GET_PERMISSION:
            return {
                ...state,
                permission: action.payload,
                loading: false
            }
        case SET_MENU_LIST:
            return {
                ...state,
                menu: action.payload,
                loading: false
            }
        case SET_USER:
            return {
                ...state,
                user: action.payload,
                loading: false
            }
        case SET_USERTYPE:
            return {
                ...state,
                userType: action.payload,
                loading: false
            }
        case SET_SIDEBAR:
            return {
                ...state,
                sidebar: action.payload,
                loading: false
            }

        case SET_IS_SUPER:
            return {
                ...state,
                isSuper: action.payload,
                loading: false
            }
        case SET_IS_ADMIN:
            return {
                ...state,
                isAdmin: action.payload,
                loading: false
            }
        case SET_COUNT:
            return {
                ...state,
                count: action.payload,
                loading: false
            }
        case SET_TOTAL:
            return {
                ...state,
                total: action.payload,
                loading: false
            }
        case SET_PAGINATION:
            return {
                ...state,
                pagination: action.payload,
                loading: false
            }
        case SET_SEARCH:
            return {
                ...state,
                search: action.payload,
                loading: false
            }
        case SET_RESPONSE:
            return {
                ...state,
                response: action.payload,
                loading: false
            }

        case SET_LOADING:
            return {
                ...state,
                loading: true
            }

        case UNSET_LOADING:
            return {
                ...state,
                loading: false
            }

        default:
            return state;
    }

}

export default reducer;