import React, { useEffect} from 'react'
import { useNavigate } from 'react-router-dom';
import loader from './loader'
import Axios from 'axios';
import storage from './storage';
import Cookies from 'universal-cookie';

const logout = async () => {
    const cookie = new Cookies();

    storage.clearAuth();
    localStorage.clear();

    // remove cookies
    cookie.remove('token');
    cookie.remove('userType');

    // await Axios.post(`${process.env.REACT_APP_AUTH_URL}/auth/logout`,{}, storage.getConfig());
}

export function useNetworkDetect(){

    
    const toggleNetwork = (e: any) => {
        loader.popNetwork()
    }

    useEffect(() => {

        window.addEventListener(`offline`, toggleNetwork, false);
        window.addEventListener(`online`, () => { }, false);

    }, [])

}

export const usePageRedirect = (types: Array<string>) => {

    const navigate = useNavigate();
    const cookie = new Cookies();

    const ut = cookie.get("userType").toString();

    useEffect(() => {
        fireRedirect()
    }, [])

    const fireRedirect = () => {

        if(!storage.checkToken() && !storage.checkUserID()){
            navigate('/login');
            logout()
        }else if(ut === '' || ut === undefined || ut === null){
            navigate('/login');
            logout()
        }else if(types.includes(ut) === false){
            navigate('/login');
            logout()
        }

    }

}
