import { IMenuList } from "./types";

const defaultMenu: Array<IMenuList> = [
    {
        name: 'dashboard',
        label: 'home',
        url: '/dashboard',
        position: 0,
        submenu: [],
        style: {
            top: '1px',
            left: '-4px'
        },
        icon: {
            name: 'board',
            paths: ["path1", "path2"],
            size: 17,
            flip: false,
            label: '',
            top: '2px',
            left: '2px'
        }
    },
    {
        name: 'settings',
        label: 'settings',
        url: '/dashboard/settings',
        position: 7,
        submenu: [
            {
                name: 'general',
                url: '/dashboard/settings/general'
            },
            {
                name: 'providers',
                url: '/dashboard/settings/providers'
            }
        ],
        style: {
            top: '0',
            left: '-6px'
        },
        icon: {
            name: 'settings',
            paths: ["path1", "path2"],
            size: 20,
            flip: false,
            label: '',
            top: '1px',
            left: '2px'
        }
    },
    {
        name: 'account',
        label: 'account',
        url: '/dashboard/account',
        position: 6,
        submenu: [
            {
                name: 'profile',
                url: '/dashboard/account/profile'
            },
            {
                name: 'audits',
                url: '/dashboard/account/audits'
            }
        ],
        style: {
            top: '0',
            left: '-5px'
        },
        icon: {
            name: 'account',
            paths: ["path1", "path2"],
            size: 20,
            flip: false,
            label: '',
            top: '1px',
            left: '2px'
        }
    },
    {
        name: 'users',
        label: 'users',
        url: '/dashboard/users',
        position: 1,
        submenu: [
            {
                name: 'all users',
                url: '/dashboard/users'
            },
            {
                name: 'administrators',
                url: '/dashboard/users/admins'
            },
            {
                name: 'compliance',
                url: '/dashboard/users/compliance'
            },
            {
                name: 'corporates',
                url: '/dashboard/users/corporates'
            },
            {
                name: 'permissions',
                url: '/dashboard/users/permissions'
            }
        ],
        style: {
            top: '0',
            left: '-8px'
        },
        icon: {
            name: 'users',
            paths: ["path1", "path2", "path3", "path4"],
            size: 23,
            flip: true,
            label: 'quad',
            top: '1px',
            left: '0'
        }
    },
    {
        name: 'payments',
        label: 'payments',
        url: '/dashboard/payments',
        position: 2,
        submenu: [
            {
                name: 'transactions',
                url: '/dashboard/transactions'
            },
            {
                name: 'chargebacks',
                url: '/dashboard/chargebacks'
            },
            {
                name: 'refunds',
                url: '/dashboard/refunds'
            },
            {
                name: 'settlements',
                url: '/dashboard/settlements'
            },
            {
                name: 'payment links',
                url: '/dashboard/payment-links'
            }
        ],
        style: {
            top: '0',
            left: '-4px'
        },
        icon: {
            name: 'card',
            paths: ["path1","path2"],
            size: 19,
            flip: false,
            label: '',
            top: '0',
            left: '1px'
        }
    },
    {
        name: 'products',
        label: 'products',
        url: '/dashboard/products',
        position: 4,
        submenu: [],
        style: {
            top: '0',
            left: '-3px'
        },
        icon: {
            name: 'shop-bag',
            paths: ["path1", "path2"],
            size: 18,
            flip: true,
            label: '',
            top: '0',
            left: '0px'
        }
    },
    {
        name: 'invoices',
        label: 'invoices',
        url: '/dashboard/invoices',
        position: 5,
        submenu: [],
        style: {
            top: '0',
            left: '-2px'
        },
        icon: {
            name: 'invoice',
            paths: ["path1", "path2"],
            size: 17,
            flip: false,
            label: '',
            top: '0',
            left: '1px'
        }
    },
    {
        name: 'finance',
        label: 'finance',
        url: '/dashboard/finance',
        position: 3,
        submenu: [
            {
                name: 'wallet',
                url: '/dashboard/finance/wallet'
            },
            {
                name: 'reconcile',
                url: '/dashboard/finance/reconcile'
            }
        ],
        style: {
            top: '0',
            left: '-4px'
        },
        icon: {
            name: 'bank',
            paths: ["path1","path2"],
            size: 19,
            flip: false,
            label: '',
            top: '0',
            left: '1px'
        }
    },
    {
        name: 'api',
        label: 'api',
        url: '/dashboard/docs',
        position: 8,
        submenu: [],
        style: {
            top: '0',
            left: '-3px'
        },
        icon: {
            name: 'link',
            paths: ["path1","path2"],
            size: 18,
            flip: false,
            label: '',
            top: '1px',
            left: '2px'
        }
    },
    {
        name: 'logout',
        label: 'logout',
        url: '',
        position: 9,
        submenu: [],
        style: {
            top: '0',
            left: '-4px'
        },
        icon: {
            name: 'power',
            paths: ["path1", "path2"],
            size: 20,
            flip: false,
            label: '',
            top: '1px',
            left: '4px'
        }
    }
]

export default defaultMenu;