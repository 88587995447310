import React from 'react'
import { IEmptyProps } from '../../utils/types'

const EmptyBox = ({ children, bgColor, size, className, bound = false }: Partial<IEmptyProps>) => {
    return (
        <>

            <div className={`empty-box ${className ? className : ''} ${size ? size : 'sm'}`} style={{ backgroundColor: `${bgColor ? bgColor : ''}` }}>

                <div className="ui-text-center">
                    {
                        bound &&
                        <div className="wp-100 ui-text-center">
                            {children}
                        </div>
                    }
                    {
                        !bound &&
                        <div className="row">
                            <div className="col-md-10 mx-auto ui-text-center">
                                {children}
                            </div>
                        </div>
                    }
                </div>

            </div>

        </>
    )
}

export default EmptyBox