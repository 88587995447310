import React, { useState, useEffect, useContext } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { ITopbarProps, IUserContext } from '../../utils/types'
import Cookies from 'universal-cookie';

import Axios from 'axios'

import storage from '../../helpers/storage'
import UserContext from '../../context/user/userContext'
import Toast from './Toast'
import body from '../../helpers/body';

const Topbar = ({ isFixed, pageTitle, showBack, barType, collapsed, barCollapsed, expandFunc }: Partial<ITopbarProps>) => {

    const navigate = useNavigate();
    const cookie = new Cookies();

    const userContext = useContext<IUserContext>(UserContext)
    const [icon, setIcon] = useState<string>('menu')
    const [mode, setMode] = useState<string>('test');

    const [toast, setToast] = useState({
        type: 'success',
        show: false,
        message: '',
        title: '',
        position: 'top-right'
    })

    useEffect(() => {

    }, [])

    const back = (e: any) => {
        if (e) e.preventDefault();
        navigate(-1)
    }

    const logout = async (e: any) => {

        if (e) e.preventDefault();

        storage.clearAuth();
        localStorage.clear()
        navigate('/');

        cookie.remove('token')
        cookie.remove('userType');
        // await Axios.post(`${process.env.REACT_APP_AUTH_URL}/auth/logout`,{}, storage.getConfig());
    }

    const openSidebar = (e: any) => {
        e.preventDefault();
        const sd = document.querySelector('.ui-sidebar');

        if (sd) {
            if (sd.classList.contains('pull-icons')) {
                sd.classList.remove('pull-icons');
                setIcon('menu')
            } else {
                sd.classList.add('pull-icons');
                setIcon('x')
            }
        }

    }

    const expandSideBar = (e: any) => {

        if (e) { e.preventDefault() }
        expandFunc(e);

    }

    const toggleToast = (e: any) => {
        if (e) e.preventDefault();
        setToast({ ...toast, show: !toast.show });
    }

    const switchMode = (e: any) => {

        if (mode === 'test' && e.target.checked === true) {
            setMode('live')
        }

        if (mode === 'live' && e.target.checked === false) {
            setMode('test')
        }

    }

    return (
        <>

            <Toast
                show={toast.show}
                title={toast.title}
                message={toast.message}
                position={toast.position}
                type={toast.type}
                close={toggleToast} />

            <div id="ui-dashboard-topbar" className={`ui-dashboard-topbar ${collapsed && collapsed === true ? 'sdbr--cllps' : 'sdbr--open'} ${isFixed ? 'stick' : ''}`}>

                {
                    collapsed && collapsed === true &&
                    <div className='ui-hide-mobile-only pdr2'>
                        {
                            barCollapsed &&
                            <Link to="" onClick={(e) => expandSideBar(e)} className="">
                                <span style={{ position: 'relative', top: '3px' }} className="fe fe-menu fs-18 onwhite"></span>
                            </Link>
                        }
                        {
                            !barCollapsed &&
                            <Link to="" onClick={(e) => expandSideBar(e)} className="">
                                <span style={{ position: 'relative', top: '3px' }} className="fe fe-x fs-18 onwhite"></span>
                            </Link>
                        }

                    </div>
                }

                {
                    showBack &&
                    <>
                        <Link to="" className="link-round sm" style={{ backgroundColor: '#decfff' }} onClick={(e) => back(e)}>
                            <span style={{ position: 'relative', top: '0px' }} className="fe fe-chevron-left fs-14 brand-purple"></span>
                        </Link>
                        <span className='pdr1'></span>
                    </>
                }

                <h1 className="page-title font-aeonik-bold brand-purple ui-relative fs-15" style={{ top: '0px' }}>{pageTitle ? pageTitle : 'Home'}</h1>

                <span className='pdl pdr1'></span>

                <div className={`${userContext.userType ? '' : 'ui-hide'}`}>
                    <span className='font-aeonikmedium fs-13 ui-relative' style={{ top: '0px', color: '#5d5985' }}>Last login &nbsp; | &nbsp; {userContext.user.login ? body.formatDateTime(userContext.user.login.last) : '---'} </span>
                </div>

                <div className="options">


                    <ul className="ui-topbar-nav">

                        {
                            mode && mode !== '' &&
                            <li className=''>
                                <label className={`custom-switch switch-fancy custom-control-inline ui-relative`} style={{ top: '6px' }}>
                                    {mode === 'test' && <input defaultChecked={true} checked={true} type="checkbox" name="switch-mode" className="custom-switch-input" id="switch-mode" />}
                                    {mode === 'live' && <input defaultChecked={true} checked={true} type="checkbox" name="switch-mode" className="custom-switch-input" id="switch-mode" />}
                                    <span className={`custom-switch-indicator mode-switch ${mode}`}></span>
                                    <span className={`custom-switch-description fs-14 font-aeonik`} style={{ color: mode === 'test' ? '#8b0455' : '#039c28' }}> {mode === '' ? 'Test Mode' : `${body.captialize(mode)} Mode`} </span>
                                </label>
                            </li>
                        }

                        <li className='pdr2 pdl1'></li>

                        <li className=''>
                            <Link to="/dashboard" className='ui-relative' style={{ top: '2px' }}>
                                <span className='bell-notify'></span>
                                <span style={{ left: '0', top: '6px' }} className='ts-icon terra-bell ui-relative'>
                                    <span className='path1 fs-22'></span>
                                    <span className='path2 fs-22'></span>
                                </span>
                            </Link>
                        </li>

                        <li className='pdr1 pdl2'></li>

                        <li className='ui-hide-mobile-only'>
                            <Link to="" className="topbar-dp">
                                {
                                    !userContext.loading && userContext.user.avatar && userContext.user.avatar !== 'no-picture.jpg' &&
                                    <img src={userContext.user.avatar} alt="dp" />
                                }
                                {
                                    !userContext.loading && userContext.user.avatar && (userContext.user.avatar === '' || userContext.user.avatar === 'no-picture.jpg') &&
                                    <img src="../../../images/assets/avatar.svg" alt="dp" />
                                }
                                {
                                    !userContext.loading && !userContext.user.avatar &&
                                    <img src="../../../images/assets/avatar.svg" alt="dp" />
                                }
                                {
                                    userContext.loading &&
                                    <img src="../../../images/assets/avatar.svg" alt="dp" />
                                }

                            </Link>

                            <div className="ui-topbar-drop">

                                <ul>
                                    <li>
                                        {/* <Link to={`/dashboard/${userContext.user.isSuper ? 'account' : 'manager/account'}`} className="font-aeonikregular fs-14">Account</Link> */}
                                    </li>

                                    <li>
                                        <Link to="" onClick={(e) => logout(e)} className="font-aeonik-medium fs-14">Logout</Link>
                                    </li>
                                </ul>

                            </div>
                        </li>

                        <li className="pdl1 ui-show-mobile-only">
                            <Link to="" onClick={(e) => openSidebar(e)} className="sd-menu brandcc-red" style={{ position: 'relative', top: '5px' }}><span className={`fe fe-${icon} fs-23`}></span></Link>
                        </li>

                    </ul>

                </div>

            </div>

        </>
    )

}

export default Topbar