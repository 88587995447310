import React, { useReducer } from 'react'
import { useNavigate } from 'react-router-dom'
import Cookies from 'universal-cookie';

import Axios from 'axios';
import storage from '../../helpers/storage'
import loader from '../../helpers/loader'

import UserContext from './userContext';
import UserReducer from './userReducer';

import {
    GET_LOGGEDIN_USER,
    SET_USERTYPE,
    SET_IS_ADMIN,
    SET_IS_SUPER,
    SET_LOADING,
    UNSET_LOADING,
    SET_SIDEBAR,
    SET_USER,
    GET_USERS,
    GET_KYB_LIST,
    GET_KYC_LIST,
    GET_BUSINESS,
    SET_PAGINATION,
    SET_TOTAL,
    SET_COUNT,
    SET_SEARCH,
    GET_OVERVIEW,
    GET_KYB_DATA,
    GET_VERIFICATION_DATA,
    GET_KYC_DATA,
    GET_SYSTEM_CONFIG,
    GET_USER,
    SET_RESPONSE,
    GET_PERMISSION_LIST,
    GET_PERMISSIONS,
    GET_DEFAULT_PERMISSIONS,
    SET_MENU_LIST,
    GET_BUSINESSES,
    GET_ADMINS,
    GET_WRITERS,
    GET_AUDITS
} from '../types'
import { IListQuery, IMenuList, ISearchProps, IUserPermission } from '../../utils/types';
import defaultMenu from '../../utils/menu.util';
import { SetMenuListDTO } from '../../dtos/user.dto';
import helperService from '../../utils/function.util';
import { NotPermittedType, UserType } from '../../utils/enums.util';

const UserState = (props: any) => {

    const cookie = new Cookies();

    const exp = new Date(
        Date.now() + 70 * 24 * 60 * 60 * 1000
    )

    const navigate = useNavigate()
    Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

    const initialState = {
        overview: {},
        config: {},
        audits: [],
        users: [],
        admins: [],
        businesses: [],
        writers: [],
        user: {},
        userDetails: {},
        permissions: [],
        permissionList: [],
        defaultPermissions: [],
        permission: {},
        transactions: [],
        transaction: {},
        KYBList: [],
        KYBData: {},
        KYCList: [],
        KYCData: {},
        verification: {},
        userType: '',
        isSuper: false,
        menu: [],
        sidebar: {},
        isAdmin: false,
        loading: false,
        total: 0,
        count: 0,
        pagination: {},
        progress: 0,
        response: {},
        search: {
            error: false,
            message: '',
            data: []
        },
    }

    const [state, dispatch] = useReducer(UserReducer, initialState);

    const logout = async () => {

        storage.clearAuth();
        localStorage.clear();
        navigate('/login');

        cookie.remove('token');
        cookie.remove('userType');

        await Axios.post(`${process.env.REACT_APP_AUTH_URL}/auth/logout`, {}, storage.getConfig());
    }

    const getSystemConfig = async () => {

        setLoading()

        await Axios.get(`${process.env.REACT_APP_AUTH_URL}/system/get-config`, storage.getConfigWithBearer())
            .then((resp) => {

                dispatch({
                    type: GET_SYSTEM_CONFIG,
                    payload: resp.data.data
                });

            }).catch((err: any) => {

                if (err && err.response && err.response.data && err.response.data.status === 401) {

                    logout();

                } else if (err && err.response && err.response.data) {

                    console.log(`Error! Could not get system config ${err.response.data}`)

                } else if (err && err.toString() === 'Error: Network Error') {

                    loader.popNetwork();

                } else if (err) {

                    console.log(`Error! Could not get system config ${err}`)

                }

                unsetLoading()

            })

    }

    const getAudits = async (data: IListQuery) => {

        const { limit, page, select, order } = data;
        const q = `limit=${limit ? limit.toString() : 20}&page=${page ? page.toString() : 1}&order=${order ? order : 'desc'}`;

        setLoading()

        await Axios.get(`${process.env.REACT_APP_AUTH_URL}/audits?${q}`, storage.getConfigWithBearer())
            .then((resp) => {

                dispatch({
                    type: GET_AUDITS,
                    payload: resp.data.data
                });

                dispatch({
                    type: SET_PAGINATION,
                    payload: resp.data.pagination
                })

                dispatch({
                    type: SET_TOTAL,
                    payload: resp.data.total
                });

                dispatch({
                    type: SET_COUNT,
                    payload: resp.data.count
                });

            }).catch((err: any) => {

                if (err && err.response && err.response.data && err.response.data.status === 401) {

                    logout();

                } else if (err && err.response && err.response.data) {

                    console.log(`Error! Could not get all audits ${err.response.data}`)

                } else if (err && err.toString() === 'Error: Network Error') {

                    loader.popNetwork();

                } else if (err) {

                    console.log(`Error! Could not get all audits ${err}`)

                }

                unsetLoading()

            })

    }

    const getOverview = async () => {

        setLoading()

        await Axios.get(`${process.env.REACT_APP_AUTH_URL}/users/overview`, storage.getConfigWithBearer())
            .then((resp) => {

                dispatch({
                    type: GET_OVERVIEW,
                    payload: resp.data.data
                });

            }).catch((err: any) => {

                if (err && err.response && err.response.data && err.response.data.status === 401) {

                    logout();

                } else if (err && err.response && err.response.data) {

                    console.log(`Error! Could not get system overview ${err.response.data}`)

                } else if (err && err.toString() === 'Error: Network Error') {

                    loader.popNetwork();

                } else if (err) {

                    console.log(`Error! Could not get system overview ${err}`)

                }

            })

    }

    const getAuthUser = async (id: string) => {

        let userId = id ? id : storage.getUserID();

        setLoading()

        await Axios.get(`${process.env.REACT_APP_AUTH_URL}/auth/user/${userId}`, storage.getConfigWithBearer())
            .then((resp) => {

                dispatch({
                    type: GET_LOGGEDIN_USER,
                    payload: resp.data.data
                });

                if (resp.data.data.permissions) {
                    dispatch({
                        type: GET_PERMISSIONS,
                        payload: resp.data.data.permissions
                    })
                }

                cookie.set("userType", resp.data.data.userType, {
                    path: '/',
                    expires: exp
                });

            }).catch((err: any) => {

                if (err && err.response && err.response.data && err.response.data.status === 401) {

                    logout();

                } else if (err && err.response && err.response.data) {

                    console.log(`Error! Could not get logged in user ${err.response.data}`)

                } else if (err && err.toString() === 'Error: Network Error') {

                    loader.popNetwork();

                } else if (err) {

                    console.log(`Error! Could not get logged in user ${err}`)

                }

            })

    }

    const getUser = async (id: string) => {

        setLoading()

        await Axios.get(`${process.env.REACT_APP_AUTH_URL}/users/${id}`, storage.getConfigWithBearer())
            .then((resp) => {

                dispatch({
                    type: GET_USER,
                    payload: resp.data.data
                });

            }).catch((err: any) => {

                const { data, status, errors, error, message } = err.response.data

                if (err && err.response && err.response.data && err.response.data.status === 401) {

                    logout();

                } else if (status && status === 403 && message === NotPermittedType.API_MESSAGE) {

                    setResponse({ status, errors, error, message, permit: { code: NotPermittedType.STATUS_CODE, message: NotPermittedType.MESSAGE } })

                } else if (err && err.response && err.response.data) {

                    console.log(`Error! Could not get logged in user ${err.response.data}`)

                } else if (err && err.toString() === 'Error: Network Error') {

                    loader.popNetwork();

                } else if (err) {

                    console.log(`Error! Could not get logged in user ${err}`)

                }

            })

    }

    const getUsers = async (limit: number, page: number) => {

        const q = `limit=${limit.toString()}&page=${page.toString()}&order=desc`;

        await Axios.get(`${process.env.REACT_APP_AUTH_URL}/users?${q}`, storage.getConfigWithBearer())
            .then((resp) => {

                dispatch({
                    type: GET_USERS,
                    payload: resp.data.data
                });

                dispatch({
                    type: SET_PAGINATION,
                    payload: resp.data.pagination
                })

                dispatch({
                    type: SET_TOTAL,
                    payload: resp.data.total
                });

                dispatch({
                    type: SET_COUNT,
                    payload: resp.data.count
                });

            }).catch((err: any) => {

                const { data, status, errors, error, message } = err.response.data

                if (err && err.response && err.response.data && err.response.data.status === 401) {

                    logout();

                } else if (status && status === 403 && message === NotPermittedType.API_MESSAGE) {

                    setResponse({ status, errors, error, message, permit: { code: NotPermittedType.STATUS_CODE, message: NotPermittedType.MESSAGE } })

                } else if (err && err.response && err.response.data) {

                    console.log(`Error! Could not get all users ${err.response.data}`)

                } else if (err && err.toString() === 'Error: Network Error') {

                    loader.popNetwork();

                } else if (err) {

                    console.log(`Error! Could not get all users ${err}`)

                }

            })

    }

    const getPermissions = async (data: IListQuery) => {

        const { limit, page, select, order } = data;
        const q = `limit=${limit ? limit.toString() : 20}&page=${page ? page.toString() : 1}&order=${order ? order : 'desc'}`;

        setLoading()

        await Axios.get(`${process.env.REACT_APP_AUTH_URL}/permissions?${q}`, storage.getConfigWithBearer())
            .then((resp) => {

                dispatch({
                    type: GET_PERMISSION_LIST,
                    payload: resp.data.data
                });

            }).catch((err: any) => {

                const { data, status, errors, message } = err.response.data

                if (status && status === 401) {

                    logout();

                } else if (status && status === 403) {

                    setResponse(err.response.data)

                } else if (data || errors || message) {

                    console.log(`Error! Could not get all permissions ${errors.length > 0 ? errors[0] : message}`)

                } else if (err && err.toString() === 'Error: Network Error') {

                    loader.popNetwork();

                } else if (err) {

                    console.log(`Error! Could not get all permissions ${err}`)

                }

                unsetLoading()

            })

    }

    const getBusinessUsers = async (type: string, data: IListQuery) => {

        const { limit, page, select, order } = data;
        const q = `limit=${limit ? limit.toString() : 20}&page=${page ? page.toString() : 1}&order=${order ? order : 'desc'}`;

        let payload = {
            businessType: type
        }

        setLoading()

        await Axios.post(`${process.env.REACT_APP_AUTH_URL}/users/filter?${q}`, { ...payload }, storage.getConfigWithBearer())
            .then((resp) => {

                dispatch({
                    type: GET_BUSINESSES,
                    payload: resp.data.data
                });

                dispatch({
                    type: SET_PAGINATION,
                    payload: resp.data.pagination
                })

                dispatch({
                    type: SET_TOTAL,
                    payload: resp.data.total
                });

                dispatch({
                    type: SET_COUNT,
                    payload: resp.data.count
                });

            }).catch((err: any) => {

                const { data, status, errors, error, message } = err.response.data

                if (err && err.response && err.response.data && err.response.data.status === 401) {

                    logout();

                } else if (status && status === 403 && message === NotPermittedType.API_MESSAGE) {

                    setResponse({ status, errors, error, message, permit: { code: NotPermittedType.STATUS_CODE, message: NotPermittedType.MESSAGE } })

                } else if (err && err.response && err.response.data) {

                    console.log(`Error! Could not get all users ${err.response.data}`)

                } else if (err && err.toString() === 'Error: Network Error') {

                    loader.popNetwork();

                } else if (err) {

                    console.log(`Error! Could not get all users ${err}`)

                }

            })

    }

    const getFilteredUsers = async (type: string, data: IListQuery) => {

        const { limit, page, select, order } = data;
        const q = `limit=${limit ? limit.toString() : 20}&page=${page ? page.toString() : 1}&order=${order ? order : 'desc'}`;

        let payload = {
            userType: type
        }

        setLoading()

        await Axios.post(`${process.env.REACT_APP_AUTH_URL}/users/filter?${q}`, { ...payload }, storage.getConfigWithBearer())
            .then((resp) => {

                if (type === UserType.ADMIN) {

                    dispatch({
                        type: GET_ADMINS,
                        payload: resp.data.data
                    });

                } else if (type === UserType.WRITER) {

                    dispatch({
                        type: GET_WRITERS,
                        payload: resp.data.data
                    });

                } else if (type === UserType.BUSINESS) {

                    dispatch({
                        type: GET_BUSINESSES,
                        payload: resp.data.data
                    });

                }

                dispatch({
                    type: SET_PAGINATION,
                    payload: resp.data.pagination
                })

                dispatch({
                    type: SET_TOTAL,
                    payload: resp.data.total
                });

                dispatch({
                    type: SET_COUNT,
                    payload: resp.data.count
                });

            }).catch((err: any) => {

                const { data, status, errors, error, message } = err.response.data

                if (err && err.response && err.response.data && err.response.data.status === 401) {

                    logout();

                } else if (status && status === 403 && message === NotPermittedType.API_MESSAGE) {

                    setResponse({ status, errors, error, message, permit: { code: NotPermittedType.STATUS_CODE, message: NotPermittedType.MESSAGE } })

                } else if (err && err.response && err.response.data) {

                    console.log(`Error! Could not get all users ${err.response.data}`)

                } else if (err && err.toString() === 'Error: Network Error') {

                    loader.popNetwork();

                } else if (err) {

                    console.log(`Error! Could not get all users ${err}`)

                }

            })

    }

    const getKYCList = async (limit: number, page: number) => {

        const q = `limit=${limit.toString()}&page=${page.toString()}&order=desc`;

        setLoading()

        await Axios.get(`${process.env.REACT_APP_AUTH_URL}/compliance/kyc-list?${q}`, storage.getConfigWithBearer())
            .then((resp) => {

                dispatch({
                    type: GET_KYC_LIST,
                    payload: resp.data.data
                });

                dispatch({
                    type: SET_PAGINATION,
                    payload: resp.data.pagination
                })

                dispatch({
                    type: SET_TOTAL,
                    payload: resp.data.total
                });

                dispatch({
                    type: SET_COUNT,
                    payload: resp.data.count
                });

            }).catch((err: any) => {

                const { data, status, errors, error, message } = err.response.data

                if (err && err.response && err.response.data && err.response.data.status === 401) {

                    logout();

                } else if (status && status === 403 && message === NotPermittedType.API_MESSAGE) {

                    setResponse({ status, errors, error, message, permit: { code: NotPermittedType.STATUS_CODE, message: NotPermittedType.MESSAGE } })

                } else if (err && err.response && err.response.data) {

                    console.log(`Error! Could not get all users ${err.response.data}`)

                } else if (err && err.toString() === 'Error: Network Error') {

                    loader.popNetwork();

                } else if (err) {

                    console.log(`Error! Could not get all users ${err}`)

                }

            })

    }

    const getKYBList = async (limit: number, page: number) => {

        const q = `limit=${limit.toString()}&page=${page.toString()}&order=desc`;

        setLoading()

        await Axios.get(`${process.env.REACT_APP_AUTH_URL}/compliance/kyb-list?${q}`, storage.getConfigWithBearer())
            .then((resp) => {

                dispatch({
                    type: GET_KYB_LIST,
                    payload: resp.data.data
                });

                dispatch({
                    type: SET_PAGINATION,
                    payload: resp.data.pagination
                })

                dispatch({
                    type: SET_TOTAL,
                    payload: resp.data.total
                });

                dispatch({
                    type: SET_COUNT,
                    payload: resp.data.count
                });

            }).catch((err: any) => {

                const { data, status, errors, error, message } = err.response.data

                if (err && err.response && err.response.data && err.response.data.status === 401) {

                    logout();

                } else if (status && status === 403 && message === NotPermittedType.API_MESSAGE) {

                    setResponse({ status, errors, error, message, permit: { code: NotPermittedType.STATUS_CODE, message: NotPermittedType.MESSAGE } })

                } else if (err && err.response && err.response.data) {

                    console.log(`Error! Could not get all users ${err.response.data}`)

                } else if (err && err.toString() === 'Error: Network Error') {

                    loader.popNetwork();

                } else if (err) {

                    console.log(`Error! Could not get all users ${err}`)

                }

            })

    }

    const getCompliance = async (data: { id: string, type: 'kyb' | 'kyc' }) => {

        const { id, type } = data

        setLoading()

        await Axios.get(`${process.env.REACT_APP_AUTH_URL}/compliance/${type}/${id}`, storage.getConfigWithBearer())
            .then((resp) => {

                if (type === 'kyb') {

                    dispatch({
                        type: GET_KYB_DATA,
                        payload: resp.data.data.kyb
                    });

                    dispatch({
                        type: GET_VERIFICATION_DATA,
                        payload: resp.data.data.verification
                    });

                }

                if (type === 'kyc') {
                    dispatch({
                        type: GET_KYC_DATA,
                        payload: resp.data.data.kyc
                    });
                    dispatch({
                        type: GET_VERIFICATION_DATA,
                        payload: resp.data.data.verification
                    });
                }

            }).catch((err: any) => {

                const { data, status, errors, error, message } = err.response.data

                if (err && err.response && err.response.data && err.response.data.status === 401) {

                    logout();

                } else if (status && status === 403 && message === NotPermittedType.API_MESSAGE) {

                    setResponse({ status, errors, error, message, permit: { code: NotPermittedType.STATUS_CODE, message: NotPermittedType.MESSAGE } })

                } else if (err && err.response && err.response.data) {

                    console.log(`Error! Could not get compliance data ${err.response.data}`)

                } else if (err && err.toString() === 'Error: Network Error') {

                    loader.popNetwork();

                } else if (err) {

                    console.log(`Error! Could not get compliance data ${err}`)

                }

            })

    }

    const setLoading = () => {
        dispatch({
            type: SET_LOADING
        })
    }

    const unsetLoading = () => {
        dispatch({
            type: UNSET_LOADING,
        })
    }

    const setUserType = (n: string) => {

        dispatch({
            type: SET_USERTYPE,
            payload: n
        })

        if (n === 'superadmin') {
            dispatch({
                type: SET_IS_SUPER,
                payload: true
            })
            dispatch({
                type: SET_IS_ADMIN,
                payload: false
            })
        } else if (n === 'admin') {
            dispatch({
                type: SET_IS_SUPER,
                payload: false
            })
            dispatch({
                type: SET_IS_ADMIN,
                payload: true
            })
        } else {
            dispatch({
                type: SET_IS_SUPER,
                payload: false
            })
            dispatch({
                type: SET_IS_ADMIN,
                payload: false
            })
        }
    }

    const getUserType = () => {
        const ut = cookie.get('userType');
        return ut.toString();
    }

    const isLoggedIn = (): boolean => {

        let flag = false;

        const ut = cookie.get('userType').toString();
        const tk = cookie.get('token').toString();

        if (storage.getUserID() && storage.getToken() && tk && ut) {
            flag = true;
        }

        return flag

    }

    const setSidebar = (a: boolean, l: string) => {
        dispatch({
            type: SET_SIDEBAR,
            payload: { active: a, label: l }
        })
    }

    const setUser = (data: any) => {
        dispatch({
            type: SET_USER,
            payload: data
        })
    }

    const setSearch = ({ error, message, data }: Partial<ISearchProps>) => {
        dispatch({
            type: SET_SEARCH,
            payload: { error, message, data }
        })
    }

    const setSystemConfig = (data: any) => {
        dispatch({
            type: GET_SYSTEM_CONFIG,
            payload: data
        })
    }

    const setVerification = (data: any) => {
        dispatch({
            type: GET_VERIFICATION_DATA,
            payload: data
        })
    }

    const setResponse = (data: any) => {

        dispatch({
            type: SET_RESPONSE,
            payload: data
        })

    }

    const setMenuList = (data: SetMenuListDTO) => {

        let menuList: Array<IMenuList> = [];
        let subList: Array<{ name: string, url: string }> = [];

        const { permissions, userPermissions } = data;
        const permissionList: Array<{ name: string, entity: string, actions: Array<string> }> = permissions.map((x) => {
            let actions = x.actions.map((m: any) => m.label)
            return { name: x.name, entity: x.entity, actions: actions }
        })

        // add default menus first
        const dashboard = defaultMenu.find((x) => x.name === 'dashboard');
        const api = defaultMenu.find((x) => x.name === 'api')
        const logout = defaultMenu.find((x) => x.name === 'logout')

        if (dashboard && api && logout) {
            menuList = [
                dashboard, api, logout
            ]
        }

        // dynamically add other menu items
        for (let i = 0; i < permissionList.length; i++) {

            let name: string = '', menu = null;
            let permission = permissionList[i];
            let exist = userPermissions.find((x) => x.entity === permission.entity)
            let mappedNames = menuList.map((x) => x.name);

            if (exist) {

                if (exist.entity === 'role' || exist.entity === 'system') {

                    name = 'settings'
                    menu = defaultMenu.find((x) => x.name === name);

                } else if (exist.entity === 'audit' || exist.entity === 'account') {

                    name = 'account'
                    menu = defaultMenu.find((x) => x.name === name);

                } else if (exist.entity === 'kyb' || exist.entity === 'kyc' || exist.entity === 'business' ||
                    exist.entity === 'user' || exist.entity === 'verification' || exist.entity === 'beneficiary') {

                    name = 'users'
                    menu = defaultMenu.find((x) => x.name === name);

                } else if (exist.entity === 'chargeback' || exist.entity === 'transaction' ||
                    exist.entity === 'refund' || exist.entity === 'subaccount' || exist.entity === 'settlement' || exist.entity === 'paymentlink') {

                    name = 'payments'
                    menu = defaultMenu.find((x) => x.name === name);

                    if (menu) {

                        if (exist.entity === 'paymentlink' && exist.entity === permission.entity) {
                            subList.push({ name: 'payment links', url: '/dashboard/payment-links' })
                        } else if (exist.entity !== 'subaccount' && exist.entity === permission.entity) {
                            subList.push({ name: `${exist.entity}s`, url: `/dashboard/${exist.entity}s` });
                        }
                    }


                } else if (exist.entity === 'product') {

                    name = 'products'
                    menu = defaultMenu.find((x) => x.name === name);

                } else if (exist.entity === 'invoice') {

                    name = 'invoices'
                    menu = defaultMenu.find((x) => x.name === name);

                } else if (exist.entity === 'wallet') {

                    name = 'finance'
                    menu = defaultMenu.find((x) => x.name === name);

                }

            }
            // capture menu
            if (menu && !mappedNames.includes(menu.name)) {

                if (menu.name === 'payments') {
                    menu.submenu = helperService.sortData(subList, 'name')
                }

                menuList.push(menu)
            }

        }


        // sort menu
        menuList = helperService.sortData(menuList, 'position');

        dispatch({
            type: SET_MENU_LIST,
            payload: menuList
        })

    }

    const setDefaultMenu = () => {

        let resultList = defaultMenu.sort((a, b) => {
            if (a.position < b.position) { return -1; }
            else if (a.position > b.position) { return 1; }
            else { return 0 }
        });

        dispatch({
            type: SET_MENU_LIST,
            payload: resultList
        })

    }

    const setDefaultPermissions = (data: Array<IUserPermission>) => {

        dispatch({
            type: GET_DEFAULT_PERMISSIONS,
            payload: data
        })

    }

    return <UserContext.Provider
        value={{
            config: state.config,
            overview: state.overview,
            users: state.users,
            audits: state.audits,
            admins: state.admins,
            businesses: state.businesses,
            writers: state.writers,
            user: state.user,
            permissions: state.permissions,
            permission: state.permission,
            permissionList: state.permissionList,
            defaultPermissions: state.defaultPermissions,
            userDetails: state.userDetails,
            business: state.business,
            KYBList: state.KYBList,
            KYBData: state.KYBData,
            KYCList: state.KYCList,
            KYCData: state.KYCData,
            verification: state.verification,
            menu: state.menu,
            sidebar: state.sidebar,
            userType: state.userType,
            isSuper: state.isSuper,
            isAdmin: state.isAdmin,
            search: state.search,
            total: state.total,
            count: state.count,
            pagination: state.pagination,
            progress: state.progress,
            loading: state.loading,
            response: state.response,
            getSystemConfig,
            setSystemConfig,
            getAudits,
            getOverview,
            getAuthUser,
            getUser,
            getUsers,
            getPermissions,
            getBusinessUsers,
            getFilteredUsers,
            getKYBList,
            getKYCList,
            getCompliance,
            setVerification,
            setUserType,
            setSidebar,
            getUserType,
            setUser,
            setSearch,
            setMenuList,
            setDefaultMenu,
            setDefaultPermissions,
            unsetLoading,
            isLoggedIn
        }}
    >
        {props.children}

    </UserContext.Provider>

}

export default UserState