export enum UserType {
    BUSINESS = 'business',
    USER = 'user',
    ADMIN = 'admin',
    SUPERADMIN = 'superadmin',
    WRITER = 'writer',
}

export enum BusinessType{
    CORPORATE = 'corporate',
    SME = 'sme-business',
    SMB = 'smb-business',
    ENTREPRENEUR = 'entrepreneur',
}

export enum SettlementStatus{
    PENDING = 'pending',
    PROCESSING = 'processing',
    COMPLETED = 'completed'
}

export enum NotPermittedType {
    STATUS_CODE = 403,
    MESSAGE = 'not_permitted',
    API_MESSAGE='Not permitted!'
}

export enum StatusType {
    ACTIVE = 'active',
    INACTIVE = 'inactive',
}

export enum TransactionStatus{
    PENDING = 'pending',
    PROCESSING = 'processing',
    SUCCESSFUL = 'successful',
    COMPLETED = 'completed',
    FAILED = 'failed',
    REFUNDED = 'refunded',
    PAID = 'paid',
    CANCELLED = 'cancelled',
    DECLINED = 'declined',
    OVERDUE = 'overdue',
}


export enum FraudStatus{
    STATUS = 'fraud',
}

export enum FeatureType{
    INVOICE = 'invoice',
    PRODUCT = 'product',
    REQUEST = 'request'
}

export enum PrefixType{
    PRODUCT = 'VPD'
}
export enum ValueType {
    FLAT = 'flat',
    PERCENTAGE = 'percentage'
}

export enum OnboardType{
    PENDING = 'pending',
    BASIC = 'basic',
    ADDRESS = 'address',
    COMPANY = 'company-info',
    FACEID = 'face-id',
    IDCARD = 'id-card',
    OWNER = 'owner-info',
    BANK = 'bank-details',
    BVN = 'bvn-number',
    NIN = 'nin-number',
    LIVENESS = 'liveness-check',
    PIN = 'transaction-pin',
    QUESTION = 'security-question'
}

export enum ComplianceStatus{
    APPROVED = 'approved',
    SUBMITTED = 'submitted',
    DECLINED = 'declined',
    PENDING = 'pending',
    ON_HOLD = 'on-hold',
}

export enum SaveActionType {
    SAVE = "save-new",
    UPDATE = "update-data"
}

export enum VerificationType{
    PENDING = 'pending',
    SUBMITTED = 'submitted',
    APPROVED = 'approved',
    DECLINED = 'declined',
    ONHOLD = 'on-hold',
}
export enum HeaderType {
    IDEMPOTENT = 'x-idempotent-key'
}
export enum DomainType{
    LIVE = 'live',
    TEST = 'test',
    NEUTRAL = 'neutral',
    PRODUCTION = 'production',
    STAGING = 'staging'
}
export enum CookieKeyType {
    XHIT = 'x-hit'
}
export enum SettleIntoType{
    WALLET = 'wallet',
    BANK = 'bank',
    TRANSACTION_BANK = 'transaction-bank'
}
export enum FilterType{
    ALL = 'all',
    DAY = 'day',
    STATUS = 'status',
    MONTH = 'month',
    CUSTOM_DATE = 'custom-date',
}

export enum CurrencyType{
    NGN = 'NGN',
    USD = 'USD'
}

export enum TransactionFeature {
    BANK_ACCOUNT = 'bank-account',
    BANK_TRANSFER = 'bank-transfer',
    BANK_SETTLEMENT = 'bank-settlement',
    WALLET_TRANSFER = 'wallet-transfer',
    WALLET_WITHDRAW = 'wallet-withdraw',
    WALLET_VAS = 'wallet-vas',
    WALLET_AIRTIME = 'wallet-airtime',
    WALLET_DATA = 'wallet-data',
    WALLET_BILL = 'wallet-bill',
    WALLET_REFUND = 'wallet-refund',
    WALLET_REVERSAL = 'wallet-reversal',
    API_REFUND = 'api-refund',
    WALLET_CHARGEBACK = 'wallet-chargeback',
    PAYMENT_LINK = 'payment-link',
    INTERNAL_CREDIT = 'internal-credit',
    INTERNAL_DEBIT = 'internal-debit',
    INTERNAL_TRANSFER = 'internal-transfer'
}